
    import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator';
    import ModelsImages from '@/components/models/ModelsImages.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import AppTabs from '@/components/blocks/base/AppTabs.vue';
    @Component({
        components: { OptionsFieldsInlineEditor, ModelsImages, AppTabs }
    })
    export default class Tabs extends Vue {
        @Prop() tabs;
        @Prop() component;

        @Emit('inputNew')
        inputE(tabindex) {
            return tabindex;
        }
    }
