
    import { Component, Prop, Vue } from 'nuxt-property-decorator';

    @Component({
        name: 'AppTab'
    })
    export default class AppTab extends Vue {
        @Prop() title!: string;
        @Prop({ default: 0 }) index!: number;

        isActive = this.$isServer && this.index === 0;
        tabsId = '';

        mounted() {
            // Получаем ID табсета из родительского компонента
            if (this.$parent && this.$parent.$data.tabsId) {
                this.tabsId = this.$parent.$data.tabsId;

                // Слушаем событие изменения активного таба для конкретного табсета
                this.$root.$on(`tabs:${this.tabsId}:change`, this.checkActive);

                // Проверяем при монтировании
                this.checkActive(Number(this.$parent.$props.value) || 0);
            }
        }

        beforeDestroy() {
            // Удаляем слушатель при удалении компонента
            if (this.tabsId) {
                this.$root.$off(`tabs:${this.tabsId}:change`, this.checkActive);
            }
        }

        checkActive(activeIndex: number) {
            this.isActive = this.index === activeIndex;
        }
    }
