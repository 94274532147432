
    import { Component, Emit, Vue, Watch, Prop } from 'nuxt-property-decorator';

    interface TabProps {
        title: string;
    }

    // Функция для генерации случайной строки
    function generateRandomId(): string {
        return (
            'tabs_' +
            Math.random()
                .toString(36)
                .substring(2, 9)
        );
    }

    @Component({})
    export default class AppTabs extends Vue {
        @Prop({ default: 0 }) value!: number;
        @Prop({ default: () => generateRandomId() }) id!: string;

        activeTab = 0;
        tabsId = '';

        created() {
            // Генерируем уникальный ID, если не передан через props
            this.tabsId = this.id || generateRandomId();
        }

        get titles() {
            return (
                this.$slots.default
                    ?.filter(slot => slot.componentOptions)
                    .map(tab => {
                        const propsData = tab.componentOptions?.propsData as TabProps;
                        return propsData?.title;
                    }) || []
            );
        }

        @Emit('input')
        onTabClick(index: number) {
            this.activeTab = index;
            this.$root.$emit(`tabs:${this.tabsId}:change`, index);
            return index;
        }

        @Watch('value', { immediate: true })
        onValueChange(val: number) {
            if (this.activeTab !== val) {
                this.activeTab = val;
                this.$root.$emit(`tabs:${this.tabsId}:change`, val);
            }
        }

        mounted() {
            this.activeTab = this.value;
            this.$root.$emit(`tabs:${this.tabsId}:change`, this.activeTab);
        }
    }
